/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'
import ParseContent from 'components/shared/ParseContent'
import {
  HeadingLayout,
  Form,
} from 'components/elements'

const StyledForm = styled.div`
  position: relative;
  z-index: 2;
`

const ContentHeading = styled.div`
  position: relative;
  z-index: 1;
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout activeHeader="Contact">
      <SEO yoast={yoast} path={path} article />

      <iframe
        title="contact"
        width="100%"
        height="570"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2478.5536554514774!2d4.795184816258781!3d51.594743312133346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c69f7ac01983cd%3A0x3f89263d977a073c!2sGoeseelsstraat%205%2C%204817%20MV%20Breda!5e0!3m2!1snl!2snl!4v1596022998262!5m2!1snl!2snl"
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
      />

      <div className="container d-flex flex-wrap">
        <StyledForm className="col-lg-6">
          <Form>
            <ParseContent content={acf.form.description} />
            <GravityForm id={acf.form.gravity_form_id} />
          </Form>
        </StyledForm>
        <ContentHeading className="col-lg-6">
          <HeadingLayout className="ml-lg-n5 mt-lg-0 mt-5" heading={acf.sidebar.background_title} />
          <ParseContent className="mt-4 mt-lg-5 pl-lg-4" content={acf.sidebar.description} />
          <a id="support" />
        </ContentHeading>
      </div>


      <div className="py-5" />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        info {
          streetname
          housenumber
          zipcode
          city
          company_name
        }

        form {
          description
          gravity_form_id
        }

        sidebar {
          background_title
          description
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

export default PageTemplate
